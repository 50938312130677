
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import Partys, {
  exportPartyData,
  exportPDFPartys,
} from "@/core/data/party";
import { Party, getPartys, deleteParty } from "@/core/data/party";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";

export default defineComponent({
  name: "Party-listing",
  components: {
    Datatable,
  },
  setup() {
    const checkedParty = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    let selectedUser = ref();

    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllParty = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<Party>>(Partys);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);
    const getListItems = function (pageNumber) {
      console.log("selectedUser.value", selectedUser.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getPartys(
          currentPage.value,
          searchData.value,
          selectedStatus.value,
          selectedUser.value
        ).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          console.log("tableData", tableData.value);
          let promiseList: any = [];
          tableData.value.forEach((item) => {
            if (userIdList.value.indexOf(item.created_by) == -1) {
              userIdList.value.push(item.created_by);
              promiseList.push(getUserName(item.created_by));
            }
            Promise.all(promiseList).then(() => {
              res(resObj);
            });
          });
          console.log("tableData", tableData.value);
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id]) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Partys Listing", ["Apps", "Partys"]);
      getListItems(1);
    });

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedParty.value.forEach((item) => {
        deleteParty(item);
      });
      checkedParty.value.length = 0;
    };
    const exportFewSites = () => {
      exportPartyData(
        selectAllParty.value ? "all" : checkedParty.value.join(",")
      ).then(() => {
        Swal.fire("Export!", "Your Party has been exported.", "success");
      });
    };
    const exportPDFPartysHanlder = () => {
      exportPDFPartys(
        selectAllParty.value ? "all" : checkedParty.value.join(",")
      ).then(() => {
        Swal.fire("Export!", "Your Party has been exported.", "success");
      });
    };
    const deletePartyItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteParty(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your Party has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addParty = (id) => {
      if (id) {
        router.push({ name: "apps-edit-party", params: { id: id } });
      } else {
        router.push({ name: "apps-add-party" });
      }
    };
    const stockPartyItem = (id) => {
      router.push({ name: "apps-stock-party", params: { id: id } });
    };
    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const hasCreate = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasEdit = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasDelete = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Company Name",
          key: "companyName",
          sortable: false,
        },
        {
          name: "Company Contact Number",
          key: "companyContactNumber",
          sortable: false,
        },
        {
          name: "Company Email",
          key: "companyEmail",
          sortable: false,
        },
        {
          name: "Name",
          key: "name",
          sortable: false,
        },
        {
          name: "Contact Number",
          key: "contactNumber",
          sortable: false,
        },
        {
          name: "Email",
          key: "email",
          sortable: false,
        },
        {
          name: "GST",
          key: "gst",
          sortable: false,
        },
        {
          name: "Logo",
          key: "logo",
          sortable: false,
        },
      ];
      if (hasEdit.value || hasDelete.value) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });

    const onClickBack = () => {
      router.back();
    }

    return {
      addParty,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteParty,
      getPartys,
      deletePartyItem,
      search,
      searchItems,
      checkedParty,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedUser,
      asyncUser,
      exportFewSites,
      exportPDFPartysHanlder,
      selectAllParty,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
      stockPartyItem,
      onClickBack
    };
  },
});
