import moment from "moment";
import ApiService from "../services/ApiService";
interface Party {
  id: number;
  companyName: string;
  companyContactNumber: string;
  companyEmail: string;
  name: string;
  contactNumber: string;
  email: string;
  gst: string;
  logo: string;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const Partys: Array<Party> = [];

const deleteParty = function (item: Party) {
  return ApiService.delete(`party/${item.id}/`);
};

const addParty = function (item, file, id) {
  const formData = new FormData();
  delete item.logo;
  for (const key in item) {
    formData.append(key, item[key]);
  }
  if (file && file[0]) {
    formData.append("logo", file[0]);
  }
  if (id) {
    return ApiService.put(`party/${id}/`, formData);
  } else {
    return ApiService.post("party/", formData);
  }
};

const exportPartyData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/party/?party_id=" + ids,
      "party-data"
    ).then((data) => res(data));
  });
};
const exportPDFPartys = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/party/?party_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const partyObjClone = function () {
  return {
    id: 0,
    companyName: "",
    companyContactNumber: "",
    companyEmail: "",
    name: "",
    contactNumber: "",
    email: "",
    gst: "",
    logo: "",
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: Party = partyObjClone();
  newItem.id = item.id;
  newItem.companyName = item.companyName;
  newItem.companyContactNumber = item.companyContactNumber;
  newItem.companyEmail = item.companyEmail;
  newItem.name = item.name;
  newItem.contactNumber = item.contactNumber;
  newItem.email = item.email;
  newItem.gst = item.gst;
  newItem.logo = item.logo;
  newItem.created_by = item.created_by;
  return newItem;
};
const getPartyById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`party/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getPartys = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`party/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceParty = jsonToInterface;
const getEmptyObjOfParty = partyObjClone;
export {
  Party,
  getPartys,
  deleteParty,
  addParty,
  getPartyById,
  jsonToInterfaceParty,
  partyObjClone,
  exportPartyData,
  exportPDFPartys,
  getEmptyObjOfParty
};

export default Partys;
